import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet"

import { Nav } from '../components/header'
import { Hero } from '../components/hero'
import { Feature } from '../components/features'
import { Content } from '../components/content'
import { SignUp } from '../components/signup'
import { Step } from '../components/steps'
import { Faq } from '../components/faq'
import { Footer } from '../components/footer'

// markup
const IndexPage = () => {

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    let debounceScroll = null
    const onScroll = () => {
      clearTimeout(debounceScroll);
      setTimeout(() => {
        setOffset(window.pageYOffset);
      }, 400);
    };
    onScroll();
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  let scrolled = false;
  if(offset > 0) {
      scrolled = true;
  }

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Owned Collectively - deposit funding community</title>
        <link rel="canonical" href="http://mysite.com/example" />
    </Helmet>
    <main>
      <title>Owned Collectively</title>
      <Nav 
        scrolled={scrolled}
      />
      <Hero />
      <Step />
      <SignUp />
      <Faq />
      <Feature />
      <Content />
      <Footer />
    </main>
    </>
  )
}

export default IndexPage
